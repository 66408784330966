<template>
    <div class="section  login-2 acclog">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 logbx1">
                    <div class="form-section">
                        <div class="heading_main text_align_left">
                            <h2><span>{{ pageTitle }} </span> </h2>
                        </div>
                        <form action="" method="POST" id="login-frm" @submit.prevent="loginFormSubmit" autocomplete="off">
                            <input type="hidden" name="redirect" value="" />
                            <div class="form-group form-box" id="email_block">
                                <input type="email" :placeholder="placeholderText('email')" name="email" v-model.trim="$v.formData.email.$model">
                                <i class="icon-material-baseline-mail-outline"></i>
                                <p class="form-error text-danger" v-if="!formStatus && $v.formData.email.$error && !$v.formData.email.required">{{ validationRequired('email.required') }}</p>
                                <p class="form-error text-danger" v-if="!formStatus && $v.formData.email.$error && !$v.formData.email.email">{{ validationRequired('email.invalid') }}</p>
                            </div>
                            <div class="form-group form-box" id="password_block">
                                <input type="password" :placeholder="placeholderText('password')" name="password" v-model.trim="$v.formData.password.$model">
                                <i class="icon-material-outline-https"></i>
                                <p class="form-error text-danger" v-if="!formStatus && $v.formData.email.$error && !$v.formData.email.required">{{ validationRequired('password.required') }}</p>
                                <p class="form-error" v-if="!formStatus && $v.formData.password.$error && !$v.formData.password.minLength">Password must be {{$v.formData.password.$params.minLength.min}} digits or long!</p>
                            </div>
                            <div class="form-group mb-0 clearfix form-box frext">                                
                                <router-link class="forgot-password" to="/account/forgot-password">{{ pageContent('forgot_password') }}</router-link>
                            </div>
                            <div class="form-group mb-0 clearfix">
                                <button type="submit" class="btn-md btn-theme float-left gradient-btn full login-btn" :data-title="button">{{ button }}</button>
                            </div>  
                            <div class="resMssg text-center mt-3" v-html="formResponse"></div>         
                            <div class="newacblock">
                                {{ pageContent('do_not_have_an_account') }} <router-link class="registration" to="/account/registration">{{ pageContent('register_here') }}</router-link>
                            </div>         
                        </form>            
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
import Translation from '../../../public/translation.json'

export default {
    data() {
        return {
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            formData: {
                email: '',
                password:''
            },
            formResponse: '',
            formStatus: true
        }
    },
    validations: {
        formData: {
            email: {required,email},
            password: {required, minLength: minLength(6)},
        }
    },
    computed: {
        button() {
            return Translation.button[this.defaultLang].login
        },
        pageTitle() {
            return Translation.pagetitle[this.defaultLang].login
        }
    },
    methods: {
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        placeholderText(field){
            return Translation.placeholder[this.defaultLang][field]
        },
        validationRequired(field){
            return Translation.validation[this.defaultLang][field]
        },
        loginFormSubmit(){
            this.formStatus = false
            this.$v.$touch()
            if(!this.$v.$invalid){      
                document.querySelector('.resMssg').style.display = 'block'
                this.formResponse = ''
                document.querySelector('.login-btn').setAttribute('disabled', true)
                document.querySelector('.login-btn').textContent = 'Please wait...';
                this.$store.dispatch('authentication', this.formData)
                    .then(res=>{            
                        if(res.status === 200){
                            this.resMsg = res.data.message;
                            this.formResponse =`<span class="alert alert-success">${res.data.message}</span>`  
                            this.resetForm()
                            setTimeout(()=>{
                                //this.$router.push('/user/dashboard')
                                window.location.href = `/user/dashboard`
                            }, 1500);                        
                        }
                        else {
                            this.formResponse =`<span class="alert alert-danger">${res.data.error}</span>`
                        }
                        
                    }).catch(err=>{
                        document.querySelector('.login-btn').removeAttribute('disabled')
                        document.querySelector('.login-btn').textContent = 'Login';
                        if(err.response.status === 500)
                            this.formResponse =`<span class="alert alert-danger">Internal Server Error!</span>`;
                        else if(err.response.status === 422)
                            this.formResponse =`<span class="alert alert-danger">${err.response.data.message}</span>`;
                        else
                            this.formResponse =`<span class="alert alert-danger">${err.response.data.error}</span>`
                    })

                //this.togellingResponse();
            }
        },
        togellingResponse(){
            setTimeout(()=>{
                document.querySelector('.resMssg').style.display = 'none'
            }, 5000)
        },
        resetForm(){
            Object.keys(this.formData).forEach(item=>{
                this.formData[item] = ''
            })    
            this.formStatus = true
        }
    }
}
</script>
